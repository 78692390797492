<template>

  <v-container id="registrationPage" class="h-100">
    <div class="remaining-space">
      <div class="py-3 mb-5">
        <div class="top-text my-3">
          <h1 class="title is-1 thin black--text">Что</h1>
          <h1 class="title is-1 black--text">везём?</h1>
        </div>
      </div>

      <v-form ref="form" v-model="valid" lazy-validation>
        <b-field expanded class="mb-0">
          <v-text-field
            label="Имя"
            v-model="regData.name"
            :rules="nameRules"
            outlined
            required
          ></v-text-field>
        </b-field>

        <b-field expanded class="mb-0">
          <v-text-field
            type="tel"
            label="Телефон"
            v-model="regData.number"
            prefix="+7"
            v-mask="'##########'"
            outlined
            inputmode="tel"
            append-icon="mdi-phone"
            :rules="phoneRules"
            required
          ></v-text-field>
        </b-field>

        <b-field expanded class="mb-0">
          <v-checkbox v-model="agreedToDataProcessing" :rules="[v => !!v || 'Необходимо согласие']">
            <template v-slot:label>
              <div>
                Даю своё согласие с
                <a
                   @click.prevent="handlePrivacyPolicyClick('https://документы.чтовезем.рф/documents/PrivacyDataPolicy')">
                  Политикой обработки персональных данных
                </a>
                и
                <a
                   @click.prevent="handlePrivacyPolicyClick('https://документы.чтовезем.рф/documents/TermsOfUse')">
                  Условиями использования
                </a>
              </div>
            </template>
          </v-checkbox>
        </b-field>
      </v-form>
    </div>

    <div class="pb-0" style="text-align: center;">
      <p class="buttons mb-0" style="display: flex; justify-content: space-between;">
        <button class="button is-light is-medium" @click="$router.push('/order/create/2')">
          <span>Назад</span>
        </button>

        <button
          :disabled="!agreedToDataProcessing || !valid"
          class="button is-light is-medium" @click="sendRegData">
          <span>Отправить</span>
          <span class="icon is-medium">
                    <i class="fas fa-chevron-right"></i>
                </span>
        </button>
      </p>
    </div>
  </v-container>

</template>

<script>

export default {
  name: 'EasyRegistration',
  data() {
    return {
      regData: {
        number: '',
        name: '',
      },

      showPass: false,

      valid: false,
      agreedToDataProcessing: false,
    };
  },
  created() {
  },
  computed: {
    phoneRules() {
      return this.$store.getters.PHONE_RULES;
    },
    nameRules() {
      return this.$store.getters.NAME_RULES;
    },
    order: {
      get() {
        return this.$store.getters.ORDER;
      },
      set(value) {
        this.$store.commit('SET_ORDER', value);
      },
    },

    auth_type() {
      return this.$store.getters.AUTH_TYPE;
    },
  },
  methods: {
    sendRegData() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('REGISTRATION', this.regData).then(() => {
          console.log('Регистрация прошла успешно');
          this.$store.dispatch('AUTH').finally(() => {
            // запрос на создание заказа
            this.$store.dispatch('CREATE_ORDER').then(() => {
              this.$buefy.toast.open({
                message: 'Заказ успешно создан',
                type: 'is-success',
              });

              this.setDefault();
              this.$router.push('/activity-feed');
              this.$store.dispatch('GET_ORDERS');
            }).catch((message) => {
              if (message === 'active order') {
                this.$buefy.toast.open({
                  message: 'У вас уже есть активный заказ',
                  type: 'is-danger',
                });
              } else {
                this.$buefy.toast.open({
                  message: 'Ошибка при создании заказа',
                  type: 'is-danger',
                });
              }
            });
          });
        }).catch((message) => {
          if (message === 'A user with the same number already exists') {
            this.$buefy.toast.open({
              message: 'Этот номер уже занят',
              type: 'is-danger'
            });
          } else {
            this.$buefy.toast.open({
              message: 'Ошибка регистрации',
              type: 'is-danger'
            });
          }
        });
      }
    },
    handlePrivacyPolicyClick(link) {
      this.$store.commit('SET_REG_DATA', this.regData);
      window.location.href = link;
    },
    setDefault() {
      this.regData = {
        number: '',
        name: '',
      };
    },
    loadRegistrationData() {
      // если есть локальная регистрация, то заполняем
      if (this.$store.getters.REG_LOCAL_DATA &&
        Object.keys(this.$store.getters.REG_LOCAL_DATA).length !== 0) {
        this.regData = this.$store.getters.REG_LOCAL_DATA;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.loadRegistrationData();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.loadRegistrationData();
    next();
  },
};

</script>

<style scope>
#registrationPage .title.is-1 {
  font-size: 4.5rem !important;
}

#registrationPage .title.is-1.thin {
  font-weight: 300;
}

#registrationPage.container {
  display: flex;
  flex-direction: column;
}

.v-icon.notranslate.material-icons {
  font-style: normal;
  margin-right: 10px;
}
</style>
